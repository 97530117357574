<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        x-small
        :title="computed_organism.significant && organism.result.detected ? 'Common Pathogen' : computed_organism.controversial && organism.result.detected ? 'Controversial Organism' : null"
        :disabled="!$auth.check({ organisms: 'edit' })"
        v-on="on"
        @click.once="loadOrganism"
      >
        <v-icon
          :color="computed_organism.new ? 'brown lighten-1' : null"
          :class="computed_organism.new || (computed_organism.significant && organism.result.detected) ? 'fa' : 'fal'"
        >
          <template v-if="computed_organism.new">
            fa-circle-star fa-beat
          </template>
          <template v-else-if="organism.result.detected">
            {{ computed_organism.significant || computed_organism.controversial ? 'fa-exclamation-triangle' : 'fa-circle-info' }}
          </template>
          <template v-else>
            fa-circle-info
          </template>
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="text-h6 py-1">
          <v-icon left>
            fal fa-disease
          </v-icon>
          Organism Details
        </div>
        <v-spacer />
        <v-btn
          icon
          tile
          right
          color="grey"
          @click.stop="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <organism-form
        :key="computed_organism.id"
        ref="organismForm"
        :organism="computed_organism"
        info
        @loading="loading = $event"
        @organism-updated="dialog = false"
      />
      <v-divider />
      <v-card-actions
        v-if="$auth.check({ organisms: 'edit' })"
      >
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          text
          :loading="loading"
          color="primary"
          @click="$refs.organismForm.updateOrganism(); $emit('updated');"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    components: {
      OrganismForm: () => import('@/components/organism/OrganismForm.vue'),
    },
    props: {
      organism: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      dialog: false,
      loading: false,
      loaded_organism: null,
    }),
    computed: {
      computed_organism () {
        return this.loaded_organism ? this.loaded_organism : this.organism
      },
    },
    methods: {
      loadOrganism () {
        this.axios.get(`/admin/organisms/${ this.organism.id }`).then((response) => {
          this.$set(this, 'loaded_organism', response.data)
        })
      },
    },
  }
</script>
